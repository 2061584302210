@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --btn-primary: rgba(10, 179, 156, 1);
  --btn-primary-hover: #099885;
  --theme-primary: #5088a9;
  --light: #d0cfcf;
  --btn-light: rgba(224, 226, 229, 1);
  --light-gray: #c7c6c6;
  --secondry-light: #8e8b8b;
  --bg-light: #eef0f2;
  --bg-primary: rgba(80, 136, 169, 1);
  --bg-dark: #405189;
  --white: #ffff;
  --gray-900: #020f29;
  --gray-500: rgba(164, 168, 178, 1);
  --grey-100: #e0e2e5;
  --brand: rgba(80, 136, 169, 1);
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primary-btn {
  background-color: var(--btn-primary) !important;
  border: none !important;
  padding: 8px 16px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  border-radius: 4px !important;
  color: rgba(255, 255, 255, 1) !important;
  &:hover {
    background-color: var(--btn-primary-hover) !important;
  }
}
.btn-fade {
  opacity: 60%;
}
.success-btn {
  font-size: 10px !important;
  background-color: rgba(10, 179, 156, 0.16) !important;
  color: #0ab39c !important;
  font-weight: 400 !important;
  border-radius: 4px !important;
  padding: 8px 12px !important;
}

.primary-btn {
  background-color: var(--btn-primary) !important;
  border: none !important;
  padding: 8px 16px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  border-radius: 4px !important;
  color: rgba(255, 255, 255, 1) !important;
  &:hover {
    background-color: var(--btn-primary-hover) !important;
  }
}

.success1-btn {
  font-weight: 600 !important;
  font-size: 10px !important;
  color: rgba(106, 218, 125, 1) !important;
  background-color: rgba(106, 218, 125, 0.32) !important;
  padding: 8px 12px 8px 12px !important;
  line-height: 15px !important;
  border-radius: 4px !important;
}

.secondary-btn {
  background-color: var(--btn-light) !important;
  border: none !important;
  padding: 12px 16px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  border-radius: 4px !important;
  color: var(--gray-500) !important;
}

.new-btn {
  border: 1px solid rgba(10, 179, 156, 1);
  border-radius: 4px;
  color: rgba(10, 179, 156, 1);
  padding: 8px 16px;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  background-color: transparent;
}

.company {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--gray-900) !important;
}

.username {
  font-size: 14px;
  font-weight: 700;
  color: var(--gray-900);
  line-height: 21px;
}
.user-role {
  font-size: 14px;
  font-weight: 400;
  opacity: 40%;

  color: var(--gray-900);
  line-height: 21px;
}
.menu-tag {
  font-size: 10px;
  font-weight: 700;
  color: var(--light);
}
.menu-item {
  font-size: 14px;
  font-weight: 400;
  transition: all 0.2s ease;
  > a {
    color: var(--light-gray);
  }
}
.activMenu {
  /* font-weight: 600; */
  > a {
    color: var(--white);
  }
}

.logout-btn {
  cursor: pointer;
  border: 1px solid rgba(224, 0, 0, 0.2);
  border-radius: 4px !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-color: rgba(224, 0, 0, 0.5);
  }
}
.wrapper {
  background-color: var(--bg-light);
  height: calc(100vh - 90px);
  overflow: auto;
}
.full-wrapper {
  background-color: var(--bg-light);
  height: calc(100vh);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
}
.time-card {
  background-color: var(--bg-dark) !important;
  .time-btn {
    font-size: 10px !important;
    padding: 8px !important;
    border-radius: 8px !important;
    font-weight: 600 !important;
    line-height: 15px !important;
    color: var(--bg-dark) !important;
  }
}
.primary-light {
  color: var(--light);
}
.secondary-light {
  color: var(--secondry-light);
}

.card-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}
.title-light {
  color: rgba(255, 255, 255, 1) !important;
  opacity: 64%;
}
.title-dark {
  color: var(--gray-900) !important;
  opacity: 64%;
}
.card-value {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
.value-light {
  color: rgba(255, 255, 255, 1);
}
.value-dark {
  color: var(--gray-900);
}

/* Table Classes */
.table-header {
  font-size: 20px;
  color: var(--gray-900);
  font-weight: 600;
  line-height: 30px;
}
.table-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: var(--gray-900);
}
.rdt_TableCell {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray-900);
  line-height: 21px;
}

.modal-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--gray-900);
  line-height: 30px;
}
.sub-title {
  color: var(--gray-900);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.input-label,
.input-field {
  color: var(--gray-900) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}
.error {
  font-size: 12px;
}
.list-item {
  padding-bottom: 16px;
  padding-top: 16px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid var(--bg-light);

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    /* height: 24px;
    width: 24px; */
    border-radius: 4px;
    padding: 8px;
    border: none;
    background-color: rgba(241, 113, 113, 0.3);
    color: #f17171;
  }
}

/* Login styles */
.login-poster {
  background-color: var(--bg-primary);
  background-image: url("./assets/bgLogin.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.login-poster::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--brand);
  opacity: 90%;
}

.login-footer,
.text-normal {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: var(--gray-900);
  > a {
    /* text-decoration: none; */
    padding-left: 5px;
    color: var(--brand);
  }
}
.condition-text {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  color: var(--gray-900);
  > span {
    font-weight: 700;
  }
}

.time-frame {
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  background-color: rgba(224, 0, 0, 0.08);
  min-width: 190px;
  > p {
    margin: 0;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: rgba(224, 0, 0, 1);
  }
  > span {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: rgba(224, 0, 0, 1);
  }
}

.slider-container {
  flex-grow: 1;

  /* font-weight: 400;
    font-size: 14;
    line-height: 21; */
}
.progress-detail p {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px;
  color: var(--gray-500);
}
.color-grey-900 {
  color: var(--gray-900) !important;
}
.slider {
  width: 100% !important;
  margin: 2px 0px !important;
}

.heading {
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 33px;
  color: var(--gray-900);
}

.questions-container {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 4px;
}

.title-400 {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray-900);
  line-height: 21px;
}

.question {
  border-bottom: 1px solid var(--grey-100);
  padding: 32px;
}
.left-question {
  width: 444px;
  display: flex;
  align-items: center;
  justify-content: end;
}

input:where([type="radio"]) {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  margin: calc(0.75em - 11px) 0.25rem 0 0;
  vertical-align: top;
  border: 0px solid #ddd;
  border-radius: 4px;
  background: var(--grey-100) no-repeat center center;
}

input[type="radio"] {
  border-radius: 50%;
}

input:where([type="checkbox"], [type="radio"]):where(
    :active:not(:disabled),
    :focus
  ) {
  border-color: #696;
  outline: none;
}

input:where([type="checkbox"], [type="radio"]):disabled {
  background: #eee;
}

input[type="checkbox"]:checked {
  background-color: #66b700;
  /* background-image : url('checkbox.svg'); */
}

input[type="radio"]:checked {
  background-color: #66b700;
  /* background-image : url('radio.svg'); */
}

.custom-radio-1 {
  width: 32px;
  height: 32px;
  cursor: pointer;
  /* accent-color: #638d31 !important; */
}

.danger:checked {
  background-color: #f32013 !important;
}
.opacity-3:checked {
  opacity: 60%;
}

.opacity-2:checked {
  opacity: 50%;
}

.opacity-1:checked {
  opacity: 40%;
}
.opacity:checked {
  opacity: 30%;
}

.custom-radio-2 {
  width: 24px;
  height: 24px;
  cursor: pointer;
  /* accent-color: #66B700 !important; */
}

.custom-radio-3 {
  width: 20px;
  height: 20px;
  cursor: pointer;
  /* accent-color: #66B700 !important; */
}

.custom-radio-4 {
  width: 16px;
  height: 16px;
  cursor: pointer;
  /* accent-color: #66B700 !important; */
}

/* range slider color */
.rangeslider__fill {
  background-color: #6ada7d !important;
}
.rangeslider__handle {
  display: none !important;
}
